import React from "react";


const Main = () => {
    return (
        <>
            <section className="content-main">
                <div className="content-header">
                    <p className="bg-red-500">Homescreen</p>
                </div>
            </section>
        </>
    )
}

export default Main;