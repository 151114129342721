import Navbar from "../components/Navbar"
import Hero from "../components/Hero"
import Footer from "../components/Footer"


const RaccoonSquad = () => {
    return (
        <>
            <Navbar />
            <Hero ></Hero>
            <main className='main-wrap'>
                test
            </main>
            <Footer></Footer>
        </>
    );
}

export default RaccoonSquad;
