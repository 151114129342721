import Navbar from "../components/Navbar"
import Hero from "../components/Hero"
import Footer from "../components/Footer"
import ConnectToMyAlgo from "../components/connect/ConnectToMyAlgo";


const Connect = () => {
    return (
        <>
            <Navbar />
            <Hero ></Hero>
            <main className='main-wrap bg-red-500'>
                <ConnectToMyAlgo />
            </main>
            <Footer></Footer>
        </>
    );
}

export default Connect;
