import React, { Fragment, useState, useEffect } from "react";
import MyAlgoConnect from '@randlabs/myalgo-connect';
import { AddressValue } from "../Navbar";
const algosdk = require('algosdk');


const ConnectToMyAlgo = () => {

    const [connectionAddress, setConnectionAddress] = useState("");

    useEffect(() => {
        if (localStorage.getItem("address")) {
            console.log("now")
            setConnectionAddress(localStorage.getItem("address"))
        }
    }, []);


    const checkSession = async () => {
        if (localStorage.getItem("address")) {
            return true;
        } else {
            return false;
        }
    }

    const disconnectSession = () => {
        console.log("kek")

        setConnectionAddress()
        localStorage.removeItem("address")
    }

    console.log("3333", connectionAddress)

    console.log("2", localStorage.getItem("address"))

    const ConnectMyAlgo = async () => {
        console.log("6", localStorage.getItem("address"))
        console.log("7", connectionAddress)



        try {
            const myAlgoWallet = new MyAlgoConnect();
            const settings = {
                shouldSelectOneAccount: true,
            }
            const accounts = await myAlgoWallet.connect(settings);
            const address = accounts.map(account => account.address);

            console.log("1", localStorage.getItem("address"))

            //set connection localstorage and state
            localStorage.setItem('address', address[0]);
            setConnectionAddress(address[0])

        } catch (err) {
            console.error(err);
        }
    }


    const SignTransaction = async (amountSent) => {
        try {
            console.log("3", localStorage.getItem("address"))

            checkSession().then(async result => {
                console.log("new one")
                console.log("rezzzzzzzzzzzz", result)
                if (result) {
                    console.log("in 4")
                    console.log("4", localStorage.getItem("address"))



                    const myAlgoWallet = new MyAlgoConnect();
                    const algodClient = new algosdk.Algodv2('', 'https://testnet-api.algonode.cloud', '');
                    const suggestedParams = await algodClient.getTransactionParams().do();


                    const from = localStorage.getItem("address")
                    const to = 'IGUCD47RCHI5SIEJBVZKW67YW34XI4WS3EWZ7YGIUJPQGDDVSSZ7HR6QOA'
                    const amount = amountSent

                    const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
                        suggestedParams,
                        from,
                        to,
                        amount
                    });

                    console.log(txn)


                    const myAlgoConnect = new MyAlgoConnect();
                    const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());

                    console.log("tree")

                    console.log(await algodClient.sendRawTransaction(signedTxn.blob).do())


                    const confirmedTxn = await algosdk.waitForConfirmation(algodClient, signedTxn.txID, 4);

                    console.log("Transaction " + signedTxn.txID + " confirmed in round ");
                    console.log(signedTxn)
                    console.log(confirmedTxn)
                }
                else {
                    console.log("in 5")
                    console.log("5", localStorage.getItem("address"))

                    const myAlgoWallet = new MyAlgoConnect();
                    const settings = {
                        shouldSelectOneAccount: true,
                    }
                    const accounts = await myAlgoWallet.connect(settings);
                    const address = accounts.map(account => account.address);

                    //set connection localstorage and state
                    localStorage.setItem('address', address[0]);
                    setConnectionAddress(address[0])

                    const algodClient = new algosdk.Algodv2('', 'https://testnet-api.algonode.cloud', '');
                    const suggestedParams = await algodClient.getTransactionParams().do();

                    const from = address[0]
                    const to = 'IGUCD47RCHI5SIEJBVZKW67YW34XI4WS3EWZ7YGIUJPQGDDVSSZ7HR6QOA'
                    const amount = amountSent

                    const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
                        from,
                        to,
                        amount,
                        suggestedParams
                    });

                    const signedTxn = await myAlgoWallet.signTransaction(txn.toByte());
                    await algodClient.sendRawTransaction(signedTxn.blob).do()
                    const confirmedTxn = await algosdk.waitForConfirmation(algodClient, signedTxn.txID, 4);

                    console.log("Transaction " + signedTxn.txID + " confirmed in round ");
                    console.log(signedTxn)
                    console.log(confirmedTxn)

                }
            })
        } catch (err) {
            console.log("fail")
            console.error(err);
        }
    };

    return (
        <Fragment>

            <AddressValue addressProp={connectionAddress} />

            <label id="mytext"></label><br />


            <button id="connect" onClick={ConnectMyAlgo}>Connect!</button>
            <button id="disconnect" onClick={disconnectSession}>Disconnect!</button>
            <button id="sign" onClick={() => SignTransaction(5000)}>sign!</button>


        </Fragment>
    );
}


export default ConnectToMyAlgo;