import React, { Fragment, useState } from "react";
import { Link, NavLink, } from "react-router-dom";

export const AddressValue = (props) => {

    const address = props.addressProp
    if (address) {
        return <h2> {address}</h2>
    } else {
        return <h2> not found</h2>
    }

}

export const Navbar = () => {
    return (
        <div className="bg-primary">


            <nav>
                {/* Desktop nav */}

                <ul className="flex flex-col p-6 md:flex-row md:space-x-8 ">


                    {/* Logo */}
                    <li className="">
                        <Link to="/">
                            <img src="images/raccoonsquad_white_logo.png"></img>
                        </Link>
                    </li>

                    <div className="border-r-2 border-dividerColor flex-initial w-32 "></div>



                    {/* HomeScreen */}

                    <li className="p-10 md:flex-row md:space-x-8">
                        <NavLink
                            activeClassName="active"
                            className="menu-link"
                            to="/"
                            exact={true}
                        >
                            <i className="icon fas fa-home"></i>
                            <span className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"> Home </span>
                        </NavLink>
                    </li>

                    {/* Staking */}

                    <li className="p-10 md:flex-row md:space-x-8">
                        <NavLink
                            activeClassName="active"
                            className="menu-link"
                            to="/staking"
                            exact={true}
                        >
                            <i className="icon fas fa-home"></i>
                            <span className="text"> Staking </span>
                        </NavLink>
                    </li>

                    {/* Connection */}

                    <li className="p-10 md:flex-row md:space-x-8">
                        <NavLink
                            activeClassName="active"
                            className="menu-link"
                            to="/connection"
                            exact={true}
                        >
                            <i className="icon fas fa-home"></i>
                            <span className="text"> Connection </span>
                        </NavLink>
                    </li>


                </ul>

                {/* mobile nav */}
            </nav>
        </div >
    )
}

export default Navbar;