import React from 'react';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

// pages
import HomeScreen from './pages/HomeScreen';
import Staking from './pages/Staking';
import Raffle from './pages/Raffle';
import Coinflip from './pages/Coinflip';
import RaccoonSquad from './pages/RaccoonSquad';
import Connect from './pages/Connect';

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/staking" element={<Staking />} />
        <Route path="/connection" element={<Connect />} />
        <Route path="/raffle" element={<Raffle />} />
        <Route path="/coinflip" element={<Coinflip />} />
        <Route path="/raccoonsquad" element={<RaccoonSquad />} />
      </Routes>
    </>
  );
}

export default App;
