import Navbar from "../components/Navbar"
import Hero from "../components/Hero"
import Footer from "../components/Footer"


const Staking = () => {
    return (
        <>
            <Navbar />
            <Hero ></Hero>
            <main className='main-wrap'>
                <p className="bg-red-500">Some nice stake</p>
            </main>
            <Footer></Footer>
        </>
    );
}

export default Staking;
