import React, { Fragment, useState } from "react";
import { Link, NavLink, } from "react-router-dom";


export const Hero = () => {
    return (
        <div className="">
            <img src="images/bg_hero_2.png" className="w-full"></img>
        </div>
    )
}

export default Hero;