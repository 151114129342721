import React, { Fragment, useState } from "react";
import { Link, NavLink, } from "react-router-dom";


export const Footer = () => {
    return (
        <div>
            <footer className="flex-grow pt-10 pb-10 site-footer bg-gray-900"> </footer>

        </div>
    )
}

export default Footer;